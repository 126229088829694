<template>
  <b-card>

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Buscar</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Buscar"
            type="text"
            class="d-inline-block"
          />
          
          <b-button
            :loading="loading"
            :disabled="loading"
            class="ml-1"
            variant="primary"
            @click="downloadPopProducts"
          >
            <span class="text-nowrap">Descargar</span>
          </b-button>
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      v-if="popProducts"
      :columns="columns"
      :rows="popProducts"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <span
          v-if="props.column.field === 'created_at'"
        >
          {{ formatDateVariant(props.row.created_at) }}
        </span>

        <span
          v-else-if="props.column.field === 'updated_at'"
        >
          {{ formatDateVariant(props.row.updated_at) }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                @click="editPopProduct(props.row)">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Editar</span>
              </b-dropdown-item>

              <!-- <b-dropdown-item
                :to="{ name: 'pop-order-detail', params: { pop_order_id: props.row.id }}">
                <feather-icon
                  icon="ListIcon"
                  class="mr-50"
                />
                <span>Detalle</span>
              </b-dropdown-item>-->
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostrando 1 a
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['20','40','60']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} items </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>



    <b-modal
        v-model="modalUpdatePopProducts"
        id="modal-login"
        cancel-variant="outline-secondary"
        centered
        title="Editar producto POP"
        hide-footer
    >

      <b-card-text v-if="popProductSelected">
        Selecciona la pestaña según la información que deseas editar de: <b>{{ popProductSelected.name }}</b>
      </b-card-text>

      <b-tabs 
        class="mt-2"
        content-class="mt-2"
        justified>
        <b-tab 
          active
          title="Cantidad">
          
          <b-card-text>
            Recuerda que la cantidad que vas a ingresar se va a sumar a la cantidad disponible en la plataforma
          </b-card-text>

          <validation-observer ref="editAmountValidation">

            <b-form
              class="mt-2" 
              @submit.prevent="editPopProductAmount">

              <b-form-group>
                <label for="amount">Cantidad a agregar:</label>
                <validation-provider
                    #default="{ errors }"
                    name="Cantidad a agregar"
                    rules="required"
                >
                  <b-form-input
                      v-model="amount"
                      id="amount"
                      type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>

              <b-spinner
                  variant="primary"
                  class="mt-4 mb-2 float-right"
                  v-show="loading"></b-spinner>

              <!-- submit button -->
              <b-button
                  variant="primary"
                  type="submit"
                  size="md"
                  class="mt-2 mb-2 float-right"
                  v-show="!loading"
              >
                Actualizar
              </b-button>

            </b-form>
          </validation-observer>

        </b-tab>
        <!-- <b-tab
          v-if="popProductSelected"
          title="Información"
        >
          <b-card-text>
            Puedes editar la información del producto
          </b-card-text>

          <validation-observer ref="editKitsValidation">

            <b-form
              class="mt-2" 
              @submit.prevent="editPopProductInfo">

              <b-form-group>
                <label for="amount">Nombre *:</label>
                <validation-provider
                    #default="{ errors }"
                    name="Nombre"
                    rules="required"
                >
                  <b-form-input
                      v-model="popProductSelected.name"
                      id="amount"
                      type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>

              <b-form-group>
                <label for="amount">Descripción:</label>
                <b-form-input
                  v-model="popProductSelected.description"
                  id="amount"
                  type="text"
                />
              </b-form-group>

              <b-spinner
                  variant="primary"
                  class="mt-4 mb-2 float-right"
                  v-show="loading"></b-spinner>

              <b-button
                  variant="primary"
                  type="submit"
                  size="md"
                  class="mt-2 mb-2 float-right"
                  v-show="!loading"
              >
                Actualizar
              </b-button>

            </b-form>
          </validation-observer>

        </b-tab> -->
      </b-tabs>
      
    </b-modal>

  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BButton, BSpinner, BTab, BTabs, BForm, BModal, BCardText,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'
import {constants} from "@/helpers/constants"
import {formatDate} from "@/helpers/utils"
import moment from 'moment';
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    localize,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
    BTab,
    BTabs,
    BForm,
    BModal,
    ValidationObserver,
    ValidationProvider,
    BCardText
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      userData: JSON.parse(localStorage.getItem('userData')),
      locale: 'es',
      required,
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Nombre',
          field: 'name',
        },
        {
          label: 'SKU',
          field: 'sku',
        },
        {
          label: 'Cantidad ingresada',
          field: 'kits_amount',
        },
        {
          label: 'Cantidad de salida',
          field: 'outgoing_kits',
        },
        {
          label: 'Disponible',
          field: 'available',
        },
        {
          label: 'Creado',
          field: 'created_at',
        },
        {
          label: 'Actualizado',
          field: 'updated_at',
        },
        {
          label: 'Acciones',
          field: 'action',
        },
      ],
      popProducts: [],
      searchTerm: '',
      status: [{
        1: 'En alistamiento',
        2: 'Despachado',
        3: 'Cancelado',
        4: 'Finalizado',
        5: 'Recibido',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      loading: false,
      modalUpdatePopProducts: false,
      popProductSelected: null,
      amount: null,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'En alistamiento'      : 'light-primary',
        Despachado : 'light-success',
        Cancelado     : 'light-danger',
        Finalizado     : 'light-warning',
        Recibido      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    formatDateVariant() {
      return date => formatDate(date)
    },
  },
  created() {
    localize(this.locale)

    this.getPopProducts()  

  },
  methods: {
    editPopProduct(popProduct) {
      this.modalUpdatePopProducts = true
      this.popProductSelected = popProduct
    },
    getPopProducts() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/pop-products', { headers }).then(response => {
        console.log('popProducts: ', response)

        this.popProducts = response.data.data.pop_products
        
      }).catch( err => {
        console.log(err)
      })
    },
    editPopProductAmount() {
      event.preventDefault()
      this.$refs.editAmountValidation.validate().then(success => {
        if (success) {
          this.loading = true

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.post(this.host + '/api/front-web/pop-products/' + this.popProductSelected.id, {
            pop_product_amount: this.amount,
          }, { headers }).then(response => {
            console.log('pop-products: ', response)
            this.loading = false

            if(response.data.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })

              this.modalUpdatePopProducts = false
              this.amount = null
              this.getPopProducts()

            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }

          }).catch( err => {
            console.log(err)
          })

        }
      })
    },
    editPopProductInfo() {

    },
    downloadPopProducts() {
      this.loading = true
      const vm = this

      let xhr = new XMLHttpRequest();
      xhr.open('post', this.host + '/api/front-web/download-pop-products', true);
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      xhr.setRequestHeader("Authorization", 'Bearer ' + this.accessToken);
      xhr.responseType = 'arraybuffer';

      xhr.onload = function () {
        vm.loading = false
        if (this.status === 200) {
          let date = moment().format('DD-MM-YYYY HH:mm:ss');

          let blob = new Blob([this.response], {type: this.getResponseHeader('content-type')});
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'productos-pop-' + ' - ' + date + '.xlsx';
          link.click();
          
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No se ha descargado el archivo',
              icon: 'SlashIcon',
              variant: 'warning',
            },
          }) 
        }
      };
      xhr.send()
      xhr.th

    }
  }

}
</script>
